import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks, Querys & State
import { useAppState } from '../state/appState';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { StandardPageBanner } from '../components/blockZone/blocks';
import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';

const SolutionTemplate = ({ data: { page } }) => {
  const { title, caption, icon, meta, pageBlocks, iconAnimation } = page || {};

  const { setGlobalTheme } = useAppState();

  useEffect(() => {
    setGlobalTheme('white');
  }, [setGlobalTheme]);

  return (
    <>
      <StandardPageBanner
        className='text-purple'
        caption={caption}
        title={title}
        icon={icon}
        animation={iconAnimation}
      />
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
};

export default SolutionTemplate;

export const pageQuery = graphql`
  query solutionQuery($slug: String!) {
    page: sanitySolution(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      title
      caption
      icon {
        ...ImageWithPreview
      }
      iconAnimation
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
